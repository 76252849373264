import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/benefit.js";
export const query = graphql`
  {
    icons: file(name: { eq: "iconset--insights" }) {
      publicURL
    }
    banner: file(name: { eq: "banner--instant-insights" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64 
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BenefitBlurb = makeShortcode("BenefitBlurb");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BenefitBlurb title="Microfiltering" icon={props.data.icons.publicURL} iconOffset={0} mdxType="BenefitBlurb">
  <p>
    Chart blocks show you the big picture first so you can use your expertise to
    know where to focus. The advanced filtering system gives you the power to
    dig into any slice of data and loot it for insights.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Charts you can merge" icon={props.data.icons.publicURL} iconOffset={1} mdxType="BenefitBlurb">
  <p>
    VariaMetrix's patented UI makes it easy to compare data by intuitive
    drag-and-drop. Discover new insights and correlations at a glance, like:
  </p>
  <ul>
    <li>Do web visits correlate to social media engagements?</li>
    <li>Does organic search traffic impact paid search impressions?</li>
    <li>Does time of day impact message conversion rate?</li>
  </ul>
  <p>
    When you interact seamlessly with data, you will see so much more than what
    meets the eye.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Contribution highlights" icon={props.data.icons.publicURL} iconOffset={2} mdxType="BenefitBlurb">
  <p>
    VariaMetrix's contribution reports show you how each channel stacks up when
    it comes to the conversions that count. These unique reports allow you to
    see if 80% of your sales are coming from 20% of your cost so you can
    allocate your budget to maximize your return.
  </p>
    </BenefitBlurb>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      