import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Link, graphql } from "gatsby"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BenefitBlurb from "../components/benefit-blurb"
import BenefitsBlock from "../components/benefits-block"
import Image from "gatsby-image"

import style from "./benefit.module.scss"

const availableComponents = { graphql, Link, BenefitBlurb }

export default ({ pageContext, children, data }) => {
  const { title, intro } = pageContext.frontmatter
  return (
    <Layout className={style.gridLayout}>
      <SEO title={title} />
      <article className={__c(style.article)}>
        <header className={__c(style.header)} data-theme="dark">
          <h1 className={__c(style.title)}>{title}</h1>
          <div className={__c(style.introduction)}>
  <p>{intro}</p>
          </div>
          <Image
            className={__c(style.bannerImage)}
            fluid={data?.banner?.childImageSharp?.fluid}
            alt="Stylized key on VariaMetrix pie chart block"
          />
        </header>
        <main className={__c(style.main)}>
          <MDXProvider components={availableComponents}>{children}</MDXProvider>
        </main>
        <BenefitsBlock className={__c(style.aside)} />
      </article>
    </Layout>
  )
}
